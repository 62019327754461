<template>
  <div>
    <div class="table">
      <router-link to="/register"><button type="button" href="/register" class="btn btn-primary">Adicionar Utilizador</button></router-link>
      <b-table striped hover :items="users" :fields="fields" :filter="filter" :per-page="perPage" :current-page="currentPage" thead-class="text-blue" @filtered="onFiltered">
        <template #cell(admin)="data">
          <span v-if="data.item.admin == true"> <i class="bx bx-check-circle" style="color: green" @click="updateUser(data.item.email, false, data.item.username)"></i></span>
          <span v-else>
            <i class="bx bx-x-circle" style="color: red" @click="updateUser(data.item.email, true, data.item.username)"></i>
          </span>
        </template>
        <template #cell(blocked)="data">
          <span v-if="data.item.blocked == true"> <i class="bx bx-lock-alt" style="color: red" @click="updateBlock(data.item.email, false, data.item.username)"></i></span>
          <span v-else>
            <i class="bx bx-lock-open-alt" style="color: green" @click="updateBlock(data.item.email, true, data.item.username)"></i>
          </span> </template
      ></b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      users: [],
      perPage: 50,
      currentPage: 1,
      filter: null,
      rows: 1,
      fields: [
        {
          key: 'username',
          label: 'Utilizador',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'admin',
          label: 'Administrador',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'blocked',
          label: 'Bloqueado',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    async allUsers() {
      try {
        await this.$store.dispatch('users');
        this.users = this.getUsers;
        console.log(this.users);
      } catch (err) {
        alert(err);
      }
    },
    /* FILTRO PARA A BARRA DE PESQUISA */
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async updateUser(email, bool, username) {
      try {
        await this.$store.dispatch('updateAdimType', {
          email: email,
          bool: bool,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Permissões do ${username} foi atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.allUsers();
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async updateBlock(email, bool, username) {
      try {
        await this.$store.dispatch('updateBlocked', {
          email: email,
          bool: bool,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Permissões do ${username} foi atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.allUsers();
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    ...mapGetters(['getUsers']),
  },
  created() {
    this.allUsers();
  },
};
</script>

<style scoped>
.table {
  margin: 0 auto;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
p {
  font-size: 20px;
}
button {
  float: right;
  margin-bottom: 20px;
  background: rgb(255, 147, 147);
  border: none;
  box-shadow: none !important;
}
button:hover,
button:focus {
  background: rgba(255, 147, 147, 0.658);
  border: white;
}
</style>
