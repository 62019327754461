<template>
  <div class="container">
    <h2>Utilizadores</h2>
    <UserTable></UserTable>
  </div>
</template>
<script>
import UserTable from "@/components/UserTable.vue";
export default {
  components: {
    UserTable,
  },
};
</script>

<style scoped>
h2{
  margin:50px 0px 0px 0px;
}
</style>
